import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { getLocationName } from "../../api/server";
import dropdownArrow from "../../assets/dropdownArrow.png";
import dropupArrow from "../../assets/dropupArrow.png";

function AirportComp({
  rideObj,
  darkMode,
  citys,
  getCustomerCity,
  isRideTypeLocal,
  setAirportSubType,
}) {
  const [customerCity, setCustomerCity] = useState("");
  const search = useLocation().search;

  const [ridetypeArraow, setRIdetyprArraow] = useState(true);
  const [cityArrow, setCityArrow] = useState(true);
  const sourceLocation = new URLSearchParams(search).get("src");
  const [lat, lng] = sourceLocation?.trim().split(",") || [];

  useEffect(() => {
    getLocationName(
      (result) => {
        const res = JSON.parse(result);
        let compound_code = res.plus_code.compound_code.slice(9);
        setCustomerCity(compound_code.split(",")[0]);
      },
      lat,
      lng
    );
  }, [lat, lng]);

  function handleSubTripType(e) {
    let selectedPickDropValue = e.target.value;
    console.log("selectedPickDropValue", selectedPickDropValue);

    if (selectedPickDropValue === "Ride Type") {
      setAirportSubType("");
      return;
    }
    setAirportSubType(selectedPickDropValue);

    console.log(rideObj);
  }

  return (
    <div>
      <div className="tophead_div">
        {!isRideTypeLocal ? (
          <div
            className={
              darkMode
                ? "inputDarkModeBackground airport_pickupDropBorder"
                : "airport_pickupDropBorder inputModeBackground"
            }
          >
            <select
              className={
                darkMode
                  ? "airport_pickupselect inputDarkModeBackground"
                  : "airport_pickupselect inputModeBackground"
              }
              onChange={(e) => {
                handleSubTripType(e);
                setRIdetyprArraow(true);
              }}
              onMouseEnter={() => setRIdetyprArraow(false)}
              onMouseLeave={() => setRIdetyprArraow(true)}
            >
              <option value="">Ride Type</option>
              <option
                selected={rideObj.data.ride_subtype !== "" ? (rideObj.data.ride_subtype === "pick_airport" ? true : false) : null}


              >
                {" "}
                Pick up from Airport {" "}
              </option>
              <option

                selected={rideObj.data.ride_subtype !== "" ? (rideObj.data.ride_subtype === "drop_airport" ? true : false) : null}


              >
                {" "}
                Drop off to Airport {" "}
              </option>
            </select>
            <div className="dropdownDiv">
              <img
                alt="dropdownIcon"
                className="dropdownIcon"
                src={ridetypeArraow ? dropdownArrow : dropupArrow}
              />
            </div>
          </div>
        ) : null}

        <div className="airport_tophead">
          <div
            className={`airport_cityBorder
              ${!cityArrow && "airport_city_active_border"}
              ${darkMode ? "inputDarkModeBackground" : "inputModeBackground"}
            `}
          >
            <select
              id="state"
              className={
                darkMode
                  ? "airport_stateselect inputDarkModeBackground"
                  : "inputModeBackground airport_stateselect"
              }
              onChange={(e) => {
                if (
                  e.target.options[e.target.selectedIndex].text !==
                  "Select a city"
                ) {
                  setCustomerCity(
                    e.target.options[e.target.selectedIndex].text
                  );
                  getCustomerCity(
                    e.target.options[e.target.selectedIndex].text
                  );
                }
                setCityArrow(true);
              }}
              value={rideObj.data.city}
              onMouseEnter={() => setCityArrow(false)}
              onMouseLeave={() => setCityArrow(true)}
            >
              <option value="">Select a city</option>
              {citys.map((item, i) => {
                if (item.city_name === customerCity) {
                  return (
                    <option value={item.code || ""} key={i}>
                      {" "}
                      {item.city_name}{" "}
                    </option>
                  );
                } else {
                  return (
                    <option value={item.code || ""} key={i}>
                      {" "}
                      {item.city_name}{" "}
                    </option>
                  );
                }
              })}
            </select>

            <div className="dropdownDiv">
              <img
                alt="dropdownIcon"
                className="dropdownIcon"
                src={cityArrow ? dropdownArrow : dropupArrow}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AirportComp;
