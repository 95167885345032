function MessageComp({ darkMode, backmassage, getCompMessage, message }) {
  return (
    <div>
      <div className="messagerow">
        <input
          name="txtname"
          className={darkMode ? "darkModeBackground" : ""}
          onChange={(e) => {
            getCompMessage(e.target.value);
          }}
          defaultValue={backmassage}
          placeholder="Note for driver"
          cols="10"
          rows="3"
          value={message}
        ></input>
      </div>
    </div>
  );
}

export default MessageComp;
