import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import "./Citycomp.css";
import { getLocationName } from "../../api/server";
import dropdownArrow from "../../assets/dropdownArrow.png";
import dropupArrow from "../../assets/dropupArrow.png";

function Citycomp({ rideObj, darkMode, citys, getCustomerCity }) {
  const [customerCity, setCustomerCity] = useState("");
  const search = useLocation().search;

  const [cityArrow, setCityArrow] = useState(true);
  const sourceLocation = new URLSearchParams(search).get("src");
  const [lat, lng] = sourceLocation?.trim().split(",") || [];

  useEffect(() => {
    getLocationName(
      (result) => {
        const res = JSON.parse(result);
        let compound_code = res.plus_code.compound_code.slice(9);
        setCustomerCity(compound_code.split(",")[0]);
      },
      lat,
      lng
    );
  }, [lat, lng]);

  return (
    <div className="city-parent-container">
      <div className={`city-div ${!darkMode && "inputModeBorder"}`}>
        <select
          id="state"
          className={`city-field
                    ${
                      rideObj.data.city === 0
                        ? "city-field-seleted"
                        : darkMode && "city-field-font"
                    }
                    ${darkMode && "cityFieldBackground"}
                `}
          onChange={(e) => {
            if (
              e.target.options[e.target.selectedIndex].text !== "Select a city"
            ) {
              setCustomerCity(e.target.options[e.target.selectedIndex].text);
              getCustomerCity(e.target.options[e.target.selectedIndex].text);
            }
            setCityArrow(true);
          }}
          value={rideObj.data.city}
          onMouseEnter={() => setCityArrow(false)}
          onMouseLeave={() => setCityArrow(true)}
        >
          <option value="" hidden>
            City
          </option>
          {citys.map((item, i) => {
            if (item.city_name === customerCity) {
              return (
                <option value={item.code || ""} key={i}>
                  {" "}
                  {item.city_name}{" "}
                </option>
              );
            } else {
              return (
                <option value={item.code || ""} key={i}>
                  {" "}
                  {item.city_name}{" "}
                </option>
              );
            }
          })}
        </select>
        <div className="dropdownDiv">
          <img
            alt="dropdownIcon"
            className="dropdownIcon"
            src={cityArrow ? dropdownArrow : dropupArrow}
          />
        </div>
      </div>
    </div>
  );
}

export default Citycomp;
