import React, { useRef, useState, useEffect, memo } from "react";
// import Navbar from '../../Components/navbar/Navbar'
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
import "./bookrideform.css";
import "@nbai/nbmap-gl/dist/nextbillion.css";
// import nextbillion, { NBMap, DirectionsService, Marker } from '@nbai/nbmap-gl'
import LocationA from "../../assets/locationA.png";
import LocationB from "../../assets/locationB.png";
import { useLocation } from "react-router-dom";
import { getUserDetail, getLocationName } from "../../api/server";

const containerStyle = {
  width: "100%",
  height: "100vh",
};

const Bookrideform = ({ setPage, rideObj, setRideObj }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const search = useLocation().search;
  const sourceLocation = new URLSearchParams(search).get("src");
  const destLocation = new URLSearchParams(search).get("dest");

  let srcArr = sourceLocation?.trim().split(",") || [];
  let [lat, lng] = srcArr;

  let destArr = destLocation?.trim().split(",") || [];
  let [destLat, destLng] = destArr;

  // const [showSearch, setShowSearch] = useState(false)
  // const [place, setPlace] = useState("")
  // const [address, setAddress] = useState("")
  // const [activeTbox, setActiveTbox] = useState(false);
  // const [userLocation, setUserLocation] = useState("");
  // const [userToLocation, setUserToLocation] = useState("");

  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [tolat, setTolet] = useState("");
  const [tolong, setTolong] = useState("");
  const [fromlat, setFromlet] = useState("");
  const [fromlong, setFromlong] = useState("");
  const [fromAddr, setFromAddr] = useState("");
  const [toAddr, setToAddr] = useState("");

  const [tolocTitle, setTolocTitle] = useState("");
  const [fromlocTitle, setFromlocTitle] = useState("");

  const [googletolat, setGoogletolat] = useState("");
  const [googletolng, setGoogletolng] = useState("");

  const [googlefromlat, setGooglefromlat] = useState("");
  const [googlefromlng, setGooglefromlng] = useState("");

  const center = {
    lat: +lat,
    lng: +lng,
  };

  const originRef = useRef();
  const destiantionRef = useRef();

  const arr = duration.split(" ");

  let [distancenum] = distance.split(" ");

  // const onLoad = React.useCallback(function callback(map) {
  //     const bounds = new window.google.maps.LatLngBounds(center);
  //     map.fitBounds(bounds);
  // }, [center])

  const onUnmount = React.useCallback(function callback(map) {}, []);

  async function calculteRote() {
    // if (destiantionRef.current.placeholder === "" || originRef.current.placeholder === "" || destiantionRef.current.value === '' || originRef.current.value === '') {
    //     return
    // }

    const directionsService = new window.google.maps.DirectionsService();

    const results = await directionsService.route({
      origin: new window.google.maps.LatLng(googletolat, googletolng),
      destination: new window.google.maps.LatLng(googlefromlat, googlefromlng),
      travelMode: window.google.maps.TravelMode.DRIVING,
    });

    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);

    setFromlet(results.routes[0]?.legs[0].start_location.lat);
    setFromlong(results.routes[0]?.legs[0].start_location.lng);
    setFromAddr(results.routes[0].legs[0].start_address);

    setTolet(results.routes[0]?.legs[0].end_location.lat);
    setTolong(results.routes[0]?.legs[0].end_location.lng);
    setToAddr(results.routes[0].legs[0].end_address);
  }

  useEffect(() => {
    if (arr.length === 4) {
      const [hours, hourstext, mint, minttext] = duration.split(" ");
      let hourstoseconds = Math.floor(hours * 3600);
      let minttoseconds = Math.floor(mint * 60);
      rideObj.data.duration = hourstoseconds + minttoseconds;
    }

    if (arr.length === 2) {
      const [mint] = duration.split(" ");
      let minttoseconds = Math.floor(mint * 60);
      rideObj.data.duration = minttoseconds;
    }
  }, [duration, arr.length, rideObj.data]);

  useEffect(() => {
    getUserDetail((result) => {
      let res = JSON.parse(result);
      if (res) {
        rideObj.data.rider.name =
          res.content.firstname + " " + res.content.lastname;
        // rideObj.data.rider.email = res.content.mynid.toLowerCase() + '@myn.global';
        rideObj.data.rider.contact = res.content.mobile;
        setRideObj(rideObj);
      }
    }, rideObj.mynId);

    getLocationName(
      (result) => {
        const res = JSON.parse(result);
        if (res) {
          rideObj.data.from.lat = res?.results[0]?.geometry?.location?.lat;
          rideObj.data.from.long = res?.results[0]?.geometry?.location?.lng;
          rideObj.data.from.title = res?.results.length
            ? res?.results[0]?.formatted_address
            : "";
          setRideObj(rideObj);
          setFromlocTitle(res?.results[0]?.formatted_address);
          setGoogletolat(res?.results[0]?.geometry?.location?.lat);
          setGoogletolng(res?.results[0]?.geometry?.location?.lng);
        }
      },
      lat,
      lng
    );

    getLocationName(
      (result) => {
        const res = JSON.parse(result);
        if (res) {
          rideObj.data.to.lat = res?.results[0]?.geometry?.location?.lat;
          rideObj.data.to.long = res?.results[0]?.geometry?.location?.lng;
          rideObj.data.to.title = res?.results.length
            ? res?.results[0]?.formatted_address
            : "";
          setRideObj(rideObj);
          setTolocTitle(res?.results[0]?.formatted_address);
          setGooglefromlat(res?.results[0]?.geometry?.location?.lat);
          setGooglefromlng(res?.results[0]?.geometry?.location?.lng);
        }
      },
      destLat,
      destLng
    );
  }, [destLat, destLng, lat, lng, rideObj, setRideObj]);

  useEffect(() => {
    rideObj.data.from.lat = +fromlat;
    rideObj.data.from.long = +fromlong;
    rideObj.data.from.title = fromAddr;
    rideObj.data.distance = distancenum;

    setRideObj(rideObj);
    if (duration !== "") {
      setPage(1);
    }
  }, [
    fromAddr,
    fromlocTitle,
    distancenum,
    duration,
    fromlat,
    fromlong,
    rideObj,
    setPage,
    setRideObj,
  ]);

  useEffect(() => {
    rideObj.data.to.lat = +tolat;
    rideObj.data.to.long = +tolong;
    rideObj.data.to.title = toAddr;
    rideObj.data.distance = distancenum;

    setRideObj(rideObj);
    if (duration !== "") {
      setPage(1);
    }
  }, [
    toAddr,
    tolocTitle,
    distancenum,
    duration,
    rideObj,
    setPage,
    setRideObj,
    tolat,
    tolong,
  ]);

  return (
    <>
      <div className="bookingpage">
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            onUnmount={onUnmount}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
          >
            <MarkerF position={center} />
            {directionsResponse && (
              <DirectionsRenderer directions={directionsResponse} />
            )}
            <></>
          </GoogleMap>
        )}

        {isLoaded && (
          <div className="innerBox">
            <div className="locationTofrom">
              <img src={LocationA} alt="LocationA" />
              <Autocomplete className="inputwidth">
                <input
                  type="text"
                  className="inputTo"
                  id={"from"}
                  placeholder={fromlocTitle}
                  ref={originRef}
                />
              </Autocomplete>
            </div>

            <div className="locationTofrom">
              <img src={LocationB} alt="LocationB" />
              <Autocomplete className="inputwidth">
                <input
                  type="text"
                  className="inputTo"
                  id={"to"}
                  placeholder={tolocTitle}
                  ref={destiantionRef}
                />
              </Autocomplete>
            </div>

            <button
              type="button"
              className="bookrideBtn"
              onClick={() => calculteRote()}
            >
              Book a ride
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(Bookrideform);
