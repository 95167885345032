import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./scheduledrideDone.css";

import bookingConfirmed from "../../assets/bookingConfirmed.png";

import backButton from "../../assets/backButtonImg.png";
import backButtonDark from "../../assets/backButtonImgDark.png";

const ScheduledrideDetails = ({ rideObj, darkMode }) => {
  const [bookingdetails, setBookingdetails] = useState({});
  const navigate = useNavigate();
  let userData = rideObj?.data;

  return (
    <>
      <div
        className={darkMode ? "darkMode scheduledDetails" : "scheduledDetails"}
      >
        <button
          className="done_back_button_div"
          onClick={() => navigate("/completed")}
        >
          <img alt="backButton" src={darkMode ? backButtonDark : backButton} />
        </button>
        <img
          id="bookingConfirmedImg"
          src={bookingConfirmed}
          // src={darkMode ? fleetUnavilableDark : fleetUnavilable}
          alt="doneimg"
        />
        <div id="message_div">
          <div className={"scheduledHead success_h2"}>
            <h2>
              <span>Yay! Your booking is confirmed</span>
            </h2>
          </div>

          {!bookingdetails.booking_status ? (
            <>
              <div className="from-row">
                <h5>Ride details will be communicated</h5>
                <h5>to you shortly</h5>
              </div>
              <div className="from-row">
                <h5>
                  Booking ID <span>{userData.booking_id}</span>
                </h5>
              </div>
            </>
          ) : (
            <div className="from-row">
              <h5>Expect a refund in 4-5 working days</h5>
            </div>
          )}
        </div>

        <div
          className={
            darkMode
              ? "tryagain_button_div darkModeScheduleRideDoneFooter"
              : "tryagain_button_div"
          }
        >
          {bookingdetails.booking_status && (
            <button onClick={() => navigate("/")}>Try again</button>
          )}
        </div>

        <div
          className={
            darkMode
              ? "ScheduleRideDoneFooter darkModeScheduleRideDoneFooter"
              : "ScheduleRideDoneFooter"
          }
        >
          <div>
            <p>
              For any queries or concerns, connect with the fleet's support team
              via the link below
            </p>
          </div>
          <a href={rideObj.data.vendorTnC}>Terms & Conditions</a>
        </div>
      </div>
    </>
  );
};

export default ScheduledrideDetails;
