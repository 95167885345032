import React, { useCallback, useRef } from "react";
import { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { TimePicker, DatePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment/moment";
import "./rideDetails.css";
import "react-calendar/dist/Calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import AirportComp from "../../Components/airport/airportComp";
import MessageComp from "../../Components/messageComp/messageComp";
import Citycomp from "../../Components/City/Citycomp";
import {
  getCity,
  getServices,
  updateUserData,
  getUserDetail,
  getLocationName,
} from "../../api/server";

import backButton from "../../assets/backButtonImg.png";
import backButtonDark from "../../assets/backButtonImgDark.png";

const RideDetails = ({
  setPage,
  page,
  rideObj,
  setRideObj,
  hasComeBack,
  setHasComeBack,
}) => {
  const [currentHour, setCurrentHour] = useState(4);
  const [oneTwoTrip, setOneTwoTrip] = useState(null);
  const [isTodayDate, setIsTodayDate] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [rideType, setRideType] = useState("airport");
  const [date, setDate] = useState(null);
  const [place, setPlace] = useState("");
  const [attribute, setAttribute] = useState("");
  const [geoCodedLocationInfo, setGeoCodedLocationInfo] = useState({
    fromValidity: false,
    toValidity: false,
  });
  const [fromLocation, setFromLocation] = useState("");
  const [toLocation, setToLocation] = useState("");
  const [isDateChanged, setIsDateChanged] = useState(false);
  const [message, setMessage] = useState("");
  const [citys, setCitys] = useState([]);
  const [customerCity, setCustomerCity] = useState();
  const [emailValidation, setEmailValidation] = useState({
    email: "",
    validity: false,
  });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  //options to pass in as props in AutoComplete , and is restricted to only indian places.
  var options = {
    types: ["geocode", "establishment"],
    componentRestrictions: {
      country: "IN",
    },
    // strictBounds: true,
  };

  const search = useLocation().search;
  const navigate = useNavigate();

  const flightNo = useRef(null);
  //const emailRef = useRef(null);
  //get source , destination , authtoken and bookingID from URL
  const sourceLocation = new URLSearchParams(search).get("src");
  const destLocation = new URLSearchParams(search).get("dest");
  const bookingId = new URLSearchParams(search).get("bookingId");
  const authtoken = new URLSearchParams(search).get("authtoken");
  const darkMode =
    new URLSearchParams(search).get("theme") === "white" ? false : true;

  //import css file for ANT-design component DatePicker and TimePicker based on the light and dark mode.
  if (darkMode) {
    document.body.classList.add("dark");
    import("./customAntDesignStyle/customStyleDark.css");
  } else {
    document.body.classList.remove("dark");
    import("./customAntDesignStyle/customStyleLight.css");
  }

  let srcArr = sourceLocation?.trim().split(",") || [];
  // let [lat, lng] = srcArr;
  let [lat, lng] =
    srcArr.length === 0
      ? [rideObj.data.from.lat, rideObj.data.from.long]
      : srcArr;

  let destArr = destLocation?.trim().split(",") || [];
  let [destLat, destLng] = destArr;

  function getCustomerCity(city) {
    setCustomerCity(city);
    rideObj.data.city_name = city;
  }

  function getCompMessage(message) {
    setMessage(message);
    rideObj.data.message = message;
  }

  // const areaRef = useRef()
  const originRef = useRef();
  const destiantionRef = useRef();

  useEffect(() => {
    getLatLngFromAddress(place)
      .then(() => {
        if (attribute === "From") {
          rideObj.data.from.title = place;
          setGeoCodedLocationInfo((prevState) => {
            return {
              ...prevState,
              fromValidity: true,
            };
          });
        } else {
          rideObj.data.to.title = place;
          setGeoCodedLocationInfo((prevState) => {
            return {
              ...prevState,
              toValidity: true,
            };
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [place, attribute]);

  useEffect(() => {
    //when ride type changes , clear all fields and if ride type===airport or local set the initial value
    //to pick_airport and 4hr
    if (!hasComeBack) {
      rideObj.data.ride_type = rideType.toLowerCase();
      rideObj.data.ride_subtype = "";
      rideObj.data.from.title = "";
      rideObj.data.to.title = "";
      rideObj.data.flight_no = "";
      rideObj.data.message = "";
      rideObj.data.date = "";
      rideObj.data.time = "";
      setOneTwoTrip("");
      setCurrentHour(4);
      setIsTodayDate(null);
      setDate(null);
      setFromLocation("");
      setToLocation("");
      setMessage("");
      setSelectedTime(null);
      setIsDateChanged(true);
      setDate(null);

      //if rideType===airport then set ride_subtype to pick_airport as its initial value
      /* if (rideType === "airport") {
        rideObj.data.ride_subtype = "pick_airport";
      } */
      if (rideType === "local") {
        rideObj.data.ride_subtype = 4;
      }
      setGeoCodedLocationInfo(() => {
        return {
          fromValidity: false,
          toValidity: false,
        };
      });
      setEmailValidation(() => {
        return {
          email: "",
          validity: false,
        };
      });
    }
  }, [rideType, hasComeBack]);

  useEffect(() => {
    if (hasComeBack) {
      if (rideObj.data.ride_type === "local") {
        setRideType(rideObj.data.ride_type);
        setCurrentHour(rideObj.data.ride_subtype);
        setFromLocation(rideObj.data.from.title);
        setGeoCodedLocationInfo((prevState) => {
          return {
            ...prevState,
            fromValidity: true,
          };
        });
        setMessage(rideObj.data.message);
      }

      if (rideObj.data.ride_type === "airport") {
        setRideType(rideObj.data.ride_type);
        setFromLocation(rideObj.data.from.title);
        setToLocation(rideObj.data.to.title);
        setGeoCodedLocationInfo(() => {
          return {
            toValidity: true,
            fromValidity: true,
          };
        });
        setMessage(rideObj.data.message);
        flightNo.current.value = rideObj.data.flight_no;
      }

      setEmailValidation(() => {
        return {
          email: rideObj.data.rider.email,
          validity: true,
        };
      });

      setIsTodayDate(
        new Date().toISOString().slice(0, 10) === rideObj.data.date
      );
    }
  }, []);

  //get the lat and long of the entered address

  function getLatLngFromAddress(address) {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address: address }, (results, status) => {
        if (
          status === window.google.maps.GeocoderStatus.OK &&
          results.length > 0
        ) {
          const { lat, lng } = results[0].geometry.location;
          resolve({ latitude: lat(), longitude: lng() });
        } else {
          reject(new Error("Unable to geocode the address."));
        }
      });
    });
  }

  async function calculteRote() {
    setTimeout(() => {
      setHasComeBack(true);
    }, 2000);

    if (flightNo.current) {
      if (flightNo.current.value.length !== 0) {
        rideObj.data.flight_no = flightNo.current.value;
      }
    }

    let routeDetails = {
      origin: originRef.current.value,
      destination: originRef.current.value,
      travelMode: window.google.maps.TravelMode.DRIVING,
    };

    if (rideType !== "local") {
      routeDetails["destination"] = destiantionRef.current.value;
    }

    const directionsService = new window.google.maps.DirectionsService();
    const results = await directionsService.route(routeDetails);

    if (rideType === "local") {
      rideObj.data.from.lat =
        results?.routes[0]?.legs[0]?.start_location?.lat();
      rideObj.data.from.long =
        results?.routes[0]?.legs[0]?.start_location?.lng();
      rideObj.data.from.title = results?.routes[0]?.legs[0]?.start_address;
      rideObj.data.city_name = customerCity;
    } else {
      rideObj.data.from.lat =
        results?.routes[0]?.legs[0]?.start_location?.lat();
      rideObj.data.from.long =
        results?.routes[0]?.legs[0]?.start_location?.lng();
      rideObj.data.from.title = results?.routes[0]?.legs[0]?.start_address;
      rideObj.data.to.lat = results?.routes[0]?.legs[0]?.end_location?.lat();
      rideObj.data.to.long = results?.routes[0]?.legs[0]?.end_location?.lng();
      rideObj.data.to.title = results?.routes[0]?.legs[0]?.end_address;
      rideObj.data.city_name = customerCity;
    }

    let [distancenum] = results.routes[0].legs[0].distance.text.split(" ");
    let arr = results?.routes[0]?.legs[0]?.duration?.text;

    if (arr.split(" ").length === 4) {
      const [hours, hourstext, mint, minttext] = arr.split(" ");
      let hourstoseconds = Math.floor(hours * 3600);
      let minttoseconds = Math.floor(mint * 60);
      rideObj.data.duration = hourstoseconds + minttoseconds;
    }

    if (arr.split(" ").length === 2) {
      const [mint] = arr.split(" ");
      let minttoseconds = Math.floor(mint * 60);
      rideObj.data.duration = minttoseconds;
    }

    rideObj.data.distance = distancenum;
    /*  rideObj.data.date = todaysdate;
    rideObj.data.time =
      (changedtime ? changedtime : rideObj.data.time) || newformatedTime; */
    rideObj.data.message = message || backmassage;
    setRideObj(rideObj);
    findCabAvailability();
  }

  const setTripType = (tripvalue) => {
    setRideType(tripvalue);
    rideObj.data.ride_type = tripvalue;
    setRideObj(rideObj);
  };

  const useEffectFromTitle = rideObj.data.from.title !== "";
  const useEffectToTitle = rideObj.data.to.title !== "";
  useEffect(() => {
    getUserDetail((result) => {
      let res = JSON.parse(result);
      if (res) {
        rideObj.data.rider.name =
          res.content.firstname + " " + res.content.lastname;
        rideObj.data.rider.contact = res.content.mobile;
        setRideObj(rideObj);
      }
    }, rideObj.mynId);

    getLocationName(
      (result) => {
        const res = JSON.parse(result);
        let compound_code = res.plus_code.compound_code.slice(9);
        setCustomerCity(compound_code.split(",")[0]);

        if (res) {
          rideObj.data.from.lat = res?.results[0]?.geometry?.location?.lat;
          rideObj.data.from.long = res?.results[0]?.geometry?.location?.lng;
          rideObj.data.from.title = res?.results.length
            ? res?.results[0]?.formatted_address
            : "";
          setRideObj(rideObj);
        }
      },
      lat,
      lng
    );

    getLocationName(
      (result) => {
        const res = JSON.parse(result);
        if (res) {
          rideObj.data.to.lat = res?.results[0]?.geometry?.location?.lat;
          rideObj.data.to.long = res?.results[0]?.geometry?.location?.lng;
          rideObj.data.to.title = res?.results.length
            ? res?.results[0]?.formatted_address
            : "";
          setRideObj(rideObj);
        }
      },
      destLat,
      destLng
    );
  }, [
    useEffectFromTitle,
    useEffectToTitle,
    destLat,
    destLng,
    lat,
    lng,
    rideObj,
    setRideObj,
  ]);

  // Find the available partners screen

  const findCabAvailability = () => {
    updateUserData(
      (result) => {
        const response = JSON.parse(result);
        if (response.status) {
          setPage(2);
        }
      },
      authtoken,
      bookingId,
      rideObj
    );
  };

  const [checkCode, setCheckCode] = useState("");

  const getAllServices = useCallback(
    (code) => {
      setCheckCode(code);
      rideObj.data.city = Number(code);
    },
    [rideObj.data]
  );

  useEffect(() => {
    getCity((result) => {
      const response = JSON.parse(result);
      if (response.status === "SUCCESS") {
        setCitys(response.result);
      }
    });
  }, []);

  useEffect(() => {
    citys.map((item) => {
      if (item.city_name === customerCity) {
        getAllServices(item.code);
        setRideObj(rideObj);
      }
      return null;
    });
  }, [citys, customerCity, rideObj, setRideObj, getAllServices]);

  /*  useEffect(() => {
    getServices((result) => {
      const response = JSON.parse(result);
      setServices(response.result);
    }, checkCode);
  }, [checkCode]); */

  const [backmassage, setBackmassage] = useState("");

  useEffect(() => {
    if (rideObj.data.message) {
      setBackmassage(rideObj.data.message);
    }
  }, [rideObj.data.message]);

  const validate = () => {
    console.log("triggered-on select of ariport ide");
    console.log("rideType", rideType);
    console.log("rideObj.data.time", rideObj.data.time);
    console.log("date", date);
    console.log("subtype", rideObj.data.ride_subtype);
    console.log(
      "geoCodedLocationInfo.fromValidity",
      geoCodedLocationInfo.fromValidity
    );
    console.log(
      "geoCodedLocationInfo.toValidity",
      geoCodedLocationInfo.toValidity
    );
    console.log("rideObj.data.city", rideObj.data.city);
    if (rideType === "local") {
      return (
        (rideType || rideObj.data.ride_type) &&
        (selectedTime || rideObj.data.time) &&
        (date || rideObj.data.date) &&
        (rideObj.data.ride_subtype || currentHour) &&
        geoCodedLocationInfo.fromValidity &&
        emailValidation.validity &&
        rideObj.data.city
      );
    }

    if (rideType === "airport") {
      console.log("airport-redner");
      return (
        (rideType || rideObj.data.ride_type) &&
        (airportRideType || rideObj.data.ride_subtype) &&
        (rideObj.data.time || selectedTime) &&
        (rideObj.data.date || date) &&
        geoCodedLocationInfo.fromValidity &&
        geoCodedLocationInfo.toValidity &&
        emailValidation.validity &&
        rideObj.data.city
      );
    }
  };

  function handleHourChange(hour) {
    setCurrentHour(hour);
    rideObj.data.ride_subtype = hour;
  }

  const disabledTime = () => {
    const currentHour = moment().hour();
    const disabledRange = [];
    for (let i = 0; i < currentHour || i < currentHour + 4; i++) {
      disabledRange.push(moment({ hour: i }));
    }
    return {
      disabledHours: () => disabledRange.map((time) => time.hour()),
    };
  };
  //range function disables the previous timing from the current hour + 4 hours

  function validateEmail(e) {
    const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "gm");
    const isValidEmail = emailRegex.test(e.target.value);

    setEmailValidation(() => {
      return {
        email: e.target.value,
        validity: isValidEmail,
      };
    });
    rideObj.data.rider.email = e.target.value;
  }

  function handleTimeChange(time, timeString) {
    if (
      isTodayDate ||
      rideObj.data.date === new Date().toISOString().slice(0, 10)
    ) {
      const hour = time.hour();
      const isPM = timeString.endsWith("pm");

      if (isPM && hour === 12) {
        // Custom hour for PM selected
        const currentHour = Number(new Date().getHours());
        setSelectedTime(
          dayjs()
            .hour(currentHour + 4)
            .minute(0)
        );
        rideObj.data.time = dayjs()
          .hour(currentHour + 4)
          .minute(0)
          .format("hh:mm A");
      } else {
        setSelectedTime(time);
        rideObj.data.time = timeString;
      }
      return;
    }
    setSelectedTime(time);
    rideObj.data.time = timeString;
  }

  function handleDateChange(selecteddate) {
    setDate(selecteddate);
    //check if selected date and today's date are equal and update state

    const currentDate = new Date(selecteddate);
    const todayDate = new Date();

    const timezoneOffset1 = currentDate.getTimezoneOffset(); // Get the time zone offset in minutes
    const timezoneOffset2 = todayDate.getTimezoneOffset();

    todayDate.setMinutes(todayDate.getMinutes() - timezoneOffset1);
    currentDate.setMinutes(currentDate.getMinutes() - timezoneOffset2); // Adjust the date by subtracting the time zone offset

    const currentDateIso = currentDate.toISOString(); // Convert to ISO 8601 format
    const todayDateIso = todayDate.toISOString();

    setIsTodayDate(todayDateIso.slice(0, 10) === currentDateIso.slice(0, 10));

    rideObj.data.date = currentDateIso.slice(0, 10);

    setSelectedTime(null);
    //to reset the time when date changes
  }
  const [airportRideType, setAirportRidetype] = useState("");
  function setAirportSubType(airportRideTypeValue) {
    console.log("airportvalaue", airportRideTypeValue);
    if (airportRideTypeValue === "") {
      rideObj.data.ride_subtype = "";
      setAirportRidetype(null);
      return;
    }
    if (airportRideTypeValue === "Pick up from Airport") {
      rideObj.data.ride_subtype = "pick_airport";
      setAirportRidetype("pick_airport");
    } else {
      rideObj.data.ride_subtype = "drop_airport";
      setAirportRidetype("drop_airport");
    }
  }
  return (
    <>
      <div className="ridedetailrow">
        <div
          className={
            darkMode ? "typeSection darkModeBackground" : "typeSection"
          }
        >
          <div>
            <div className="back_button_div">
              <button onClick={() => navigate("/completed")}>
                <img
                  alt="backButton"
                  src={darkMode ? backButtonDark : backButton}
                />
              </button>
              <div id="header_line_div">
                <p
                  className={
                    darkMode ? "header_line darkModeBackground" : "header_line"
                  }
                >
                  RideForSure <sub id="header_sub_text">TM</sub>
                </p>
              </div>
            </div>
            <p className="tagline">
            Schedule rides in advance for the airport or hire a <br /> cab on an hourly basis for a day around town.
            </p>
          </div>

          <div className={darkMode ? "ridetype-dark" : "ridetype-light"}>
            {/* services are fetched from api and looped through */}
            {["airport", "local"]?.map((value, index) => {
              return (
                <div>
                  <input
                    type="radio"
                    className="ridetypeinput"
                    name="ridetypes"
                    id={`ridetype0${index}`}
                    checked={rideType === value}
                    onChange={() => {
                      setHasComeBack(false);
                      setTripType(value);
                    }}
                  />
                  <label htmlFor={`ridetype0${index}`} className="ridetypelbl">
                    {value.charAt(0).toUpperCase() + value.slice(1)}
                  </label>
                </div>
              );
            })}
          </div>

          {/* display One-way and two-way if rideType is Outstation */}
          {rideType === "Outstation" && (
            <div className={darkMode ? "trip-type-dark" : "trip-type-light"}>
              <div>
                <input
                  type="radio"
                  className="triptypeinput"
                  name="subtriptype"
                  id="triptype01"
                  checked={oneTwoTrip === "One-Way"}
                  onChange={() => setOneTwoTrip("One-Way")}
                />
                <label htmlFor="triptype01" className="triptypelbl">
                  <p>One way</p>
                  <p>we drop you</p>
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  className="triptypeinput"
                  name="subtriptype"
                  id="triptype02"
                  checked={oneTwoTrip === "Round-Trip"}
                  onChange={() => setOneTwoTrip("Round-Trip")}
                />
                <label htmlFor="triptype02" className="triptypelbl">
                  <p>Round trip</p>
                  <p>Travel in the cab till return </p>
                </label>
              </div>
            </div>
          )}

          {rideType === "airport" && (
            <AirportComp
              rideObj={rideObj}
              darkMode={darkMode}
              citys={citys}
              getCustomerCity={getCustomerCity}
              isRideTypeLocal={rideType === "local" ? true : false}
              setAirportSubType={setAirportSubType}
            />
          )}

          {/* show hours section when the rideType is local */}
          {rideType === "local" && (
            <div className={darkMode ? "hours-dark" : "hours-light"}>
              <div className="hours-controls">
                {/* for hours selection */}
                {[4, 8, 12].map((value, index) => {
                  return (
                    <div>
                      <input
                        type="radio"
                        className="hoursinput"
                        name="hours"
                        id={`hrlbl0${index}`}
                        checked={currentHour === Number(`${value}`)}
                        onChange={() => {
                          handleHourChange(Number(`${value}`));
                        }}
                      />
                      <label htmlFor={`hrlbl0${index}`} className="hourslbl">
                        {value}hr
                      </label>
                      <span
                        className={`hour-underline-text ${currentHour === Number(`${value}`) &&
                          "selcted-underline-text"
                          }`}
                      >
                        For <span>{value}0</span>kms
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/* show pick-up/drop and city selection when rideType is airport */}

          <div
            className={
              darkMode
                ? "ride-details-container-dark"
                : "ride-details-container-light"
            }
          >
            {rideType === "local" && (
              <Citycomp
                rideObj={rideObj}
                darkMode={darkMode}
                citys={citys}
                getCustomerCity={getCustomerCity}
                isRideTypeLocal={rideType === "local" ? true : false}
              />
            )}
            {/* isLoaded has a boolean value , shows auto-complete once the google-maps-locations are loaded */}
            {isLoaded && (
              <div className="auto-complete-container">
                {/* show auto-complete FROM field only rideType is either airport , local or outstation*/}
                {rideType === "airport" ||
                  rideType === "local" ||
                  rideType === "Outstation" ? (
                  <Autocomplete
                    className="autocomplete"
                    options={options}
                    onLoad={(autocomplete) => {
                      // Set the Autocomplete instance to use later
                      /* const bounds = {
                        north: 12.994,
                        south: 12.7975,
                        east: 74.9513,
                        west: 74.7252,
                      };
                      autocomplete.setBounds(bounds); */
                      autocomplete.addListener("place_changed", () => {
                        const place = autocomplete.getPlace();
                        setPlace(`${place.name} , ${place.formatted_address}`);
                        setAttribute("From");
                        setFromLocation(
                          `${place.name} , ${place.formatted_address}`
                        );
                      });
                    }}
                  >
                    <input
                      type="text"
                      id="tofield2"
                      //ant-picker css-dev-only-do-not-override-15rg2km class is for 100% width for this input field
                      //reusing ant-picker css styles , since 100% width not happening with raw css
                      className={"ant-picker css-15rg2km ride-details-controls"}
                      placeholder={"From"}
                      ref={originRef}
                      name="From"
                      value={fromLocation}
                      onInput={(e) => {
                        if (e.target.value.length === 0) {
                          geoCodedLocationInfo.fromValidity = false;
                        }
                        setFromLocation(e.target.value);
                      }}
                    />
                  </Autocomplete>
                ) : null}

                {/* show auto-complete TO field when ridetype is either airport or Outstation */}
                {rideType === "airport" || rideType === "Outstation" ? (
                  <Autocomplete
                    className="autocomplete"
                    options={options}
                    onLoad={(autocomplete) => {
                      // Set the Autocomplete instance to use later
                      autocomplete.addListener("place_changed", () => {
                        const place = autocomplete.getPlace();
                        setPlace(`${place.name} , ${place.formatted_address}`);
                        setAttribute("To");
                        setToLocation(
                          `${place.name} , ${place.formatted_address}`
                        );
                      });
                    }}
                  >
                    <input
                      type="text"
                      id="tofield2"
                      //ant-picker css-dev-only-do-not-override-15rg2km class is for 100% width for this input field
                      //reusing ant-picker css styles , since 100% width not happening with raw css
                      className={"ant-picker css-15rg2km ride-details-controls"}
                      placeholder={"To"}
                      ref={destiantionRef}
                      value={toLocation}
                      onInput={(e) => {
                        if (e.target.value.length === 0) {
                          geoCodedLocationInfo.toValidity = false;
                        }
                        setToLocation(e.target.value);
                      }}
                    />
                  </Autocomplete>
                ) : null}
              </div>
            )}

            {/* DatePicker from ANT-design for customizing */}
            <DatePicker
              format="DD-MM-YYYY"
              className={"ride-details-controls"}
              placeholder="Pick up Date"
              allowClear={false}
              inputReadOnly
              onChange={(selecteddate) => {
                rideObj.data.time = "";
                handleDateChange(selecteddate);
                setIsDateChanged(true);
              }}
              defaultValue={
                rideObj.data.date !== "" ? dayjs(rideObj.data.date) : null
              }
              value={hasComeBack ? undefined : date}
              disabledDate={(current) => {
                // Disable dates before today (previous dates)
                return current && current < new Date().setHours(0, 0, 0, 0);
              }}
            />

            <TimePicker
              disabledTime={isTodayDate ? () => disabledTime() : null} //check if today's date , call range() if true
              minuteStep={15}
              className={"ride-details-controls"}
              use12Hours //for 12-hours format
              format="hh:mm a"
              placeholder="Pick up Time"
              allowClear={false}
              inputReadOnly
              // defaultOpenValue={
              //   isTodayDate
              //     ? moment()
              //         .hour(new Date().getHours() + 4)
              //         .minute(0)
              //     : moment().hour(0).minute(0)
              // }
              defaultValue={
                rideObj.data.time !== ""
                  ? dayjs(
                    `${rideObj.data.time.split(" ")[0]} ${rideObj.data.time
                      .split(" ")[1]
                      .toUpperCase()}`,
                    "HH:mm A"
                  )
                  : undefined
              }
              value={
                (hasComeBack && isDateChanged) || isDateChanged
                  ? selectedTime
                  : undefined
              }
              onChange={(time, timeString) => {
                handleTimeChange(time, timeString);
              }}
            />

            <input
              type="text"
              placeholder="Email ID"
              className={"ride-details-controls"}
              onInput={(e) => validateEmail(e)}
              value={emailValidation.email}
            />

            {rideType === "airport" && (
              <input
                type="text"
                placeholder="Flight number (optional)"
                className={"ride-details-controls"}
                ref={flightNo}
              />
            )}
          </div>
          {/* a message section contains text to be sent to driver */}
          <div className="messagerow">
            <MessageComp
              darkMode={darkMode}
              backmassage={backmassage}
              getCompMessage={getCompMessage}
              message={message}
            />
          </div>

          <div className="footbtn">
            <button
              className={
                darkMode ? "findcabBtn darkModeFindcabBtn" : "findcabBtn"
              }
              disabled={!validate()}
              onClick={() => {
                calculteRote();
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RideDetails;
