import "./opretordetails.css";

import ExclamationIconDark from "../../assets/ExclamationIconDark.png";
import ExclamationIcon from "../../assets/ExclamationIcon.png";

const Opretordetails = ({
  OpretorName,
  Opretorlogo,
  OpretorPrice,
  carType,
  setBookingBtn,
  vId,
  partnership,
  catId,
  darkMode,
  vendorTnC,
  fixed_price_offer
}) => {
  return (
    <div
      className={`operator_card ${darkMode ? "dark_mode_border" : "light_mode_background"
        } `}
    >
      <div className="opretorList">
        <div className="opretorhead">
          <div className="opretorLogo">
            <span>{OpretorName}</span>{" "}
            {/* <img src={Opretorlogo} alt="opratorlogo" /> */}
          </div>
          <div>
            <h2>₹ {OpretorPrice}</h2>
            <p>{OpretorName === "wavescab" ? "Excluding Toll": "Includes Toll" }</p>
          </div>
        </div>
        <div className="tandc_parent_div">
          <div>
            <div className="driverDetails">
              <div className="vendorLink">
                <img
                  alt="roundExclamation"
                  src={darkMode ? ExclamationIcon : ExclamationIconDark}
                />
                <a
                  href={vendorTnC}
                  rel="noreferrer"
                  target="_blank"
                  className="tclink"
                >
                  Refund / Cancellation T&C
                </a>
              </div>
            </div>
            <div className="driverDetails">
              <div className="driverName">
                {/* <h5>Shiva D. ★ 4.5</h5> */}
                <h6>{carType}</h6>
              </div>
            </div>
          </div>
          <img id="opretor_img" src={Opretorlogo} alt="opratorlogo" />
        </div>
        <button
          className={
            darkMode ? "bookOperator darkModeFindcabBtn" : "bookOperator"
          }
          onClick={() => {
            setBookingBtn(
              vId,
              catId,
              OpretorName,
              OpretorPrice,
              partnership,
              carType,
              vendorTnC,
              fixed_price_offer
            );
          }}
        >
          Select this car
        </button>
      </div>
    </div>
  );
};

export default Opretordetails;
