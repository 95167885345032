import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/navbar/Navbar";
import "../scheduledrideDone/scheduledrideDone.css";
// import Avatar from '../../assets/avatar.png'
import SvariLogo from "../../assets/savari_logo.png";
import { useLocation } from "react-router-dom";
import { getUserdata } from "../../api/server";
import BookingDone from "../../assets/bookingDone.png";
import { dark } from "@mui/material/styles/createPalette";

import bookingConfirmed from "../../assets/bookingConfirmed.png";
import bookingFailed from "../../assets/bookingFailed.png";
import paymentFailed from "../../assets/paymentFailed.png";
import fleetUnavilable from "../../assets/fleetUnavilable.png";
import paymentFailedDark from "../../assets/paymentFailedDark.png";
import fleetUnavilableDark from "../../assets/fleetUnavilableDark.png";

const ScheduledrideDetails = ({ rideObj, darkMode }) => {
  const [bookingdetails, setBookingdetails] = useState({});
  const [showImage, setShowImage] = useState(false);
  const navigate = useNavigate();
  let userData = rideObj?.data;

  return (
    <>
      <div
        className={darkMode ? "darkMode scheduledDetails" : "scheduledDetails"}
      >
        <img
          id="bookingConfirmedImg"
          src={bookingFailed}
          // src={darkMode ? fleetUnavilableDark : fleetUnavilable}
          alt="doneimg"
        />
        <div id="message_div">
          <div className={`scheduledHead failed_h2`}>
            <h2>
              <span>Oh no!</span>
            </h2>
            <h2>
              <span>Your booking didn't go through</span>
            </h2>
          </div>

          <div className="from-row">
            <h5>Expect a refund in 4-5 working days</h5>
          </div>
        </div>

        <div
          className={
            darkMode
              ? "tryagain_button_div darkModeScheduleRideDoneFooter"
              : "tryagain_button_div"
          }
        >
          <button onClick={() => navigate("/completed")}>Try again</button>
        </div>

        <div
          className={
            darkMode
              ? "ScheduleRideDoneFooter darkModeScheduleRideDoneFooter"
              : "ScheduleRideDoneFooter"
          }
        >
          <div>
            <p>For any queries or concerns, connect with the fleet's support team via the link below</p>
          </div>
          <a href={rideObj.data.vendorTnC}>Terms & Conditions</a>
        </div>
      </div>
    </>
  );
};

export default ScheduledrideDetails;
