import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import "./home.css";
import Bookrideform from "../bookrideform/Bookrideform";
import RideDetails from "../rideDetails/RideDetails";
import OrganizationList from "../OperatorOffers/OperatorOffers";
import ScheduledrideDone from "../scheduledrideDone/ScheduledrideDone.js";
import SchedulerideFail from "../schedulerideFail/schedulerideFail.js";
import { useLocation } from "react-router-dom";
import RideForSureDetails from "../rideForSureDetails/rideForSureDetails";

const Home = () => {
  const location = useLocation();
  const [page, setPage] = useState(1);
  const search = useLocation().search;
  const rideId = new URLSearchParams(search).get("bookingId");
  const mynId = new URLSearchParams(search).get("mynId");
  const darkMode = new URLSearchParams(search).get("theme") === "white" ? false : true;
  // const [darkMode, setDarkMode] = useState();
  const newUuid = uuidv4();
  const [hasComeBack, setHasComeBack] = useState(false);

  const [rideObj, setRideObj] = useState({
    mynId: mynId,
    rideId: newUuid,
    data: {
      username: mynId,
      ride_id: newUuid,
      rider: {
        name: "",
        email: "",
        contact: 0,
      },
      from: {
        title: "",
        lat: 0,
        long: 0,
      },
      to: {
        title: "",
        lat: 0,
        long: 0,
      },
      city: 0,
      // locality: 0,
      // locality_name: "",
      ride_type: "",
      ride_subtype: "",
      date: "",
      time: "",
      category: "",
      category_name: "",
      distance: 0,
      duration: 0,
      flight_no: "",
      message: "",
      order_id: "",
      payment_id: "",
      amount: 0,
      currency: "",
    },
  });


  // useEffect(() => {
  //   const theme = new URLSearchParams(search).get("theme") === "white" ? false : true;
  //   localStorage.setItem("dark_mode", theme);
  //   // localStorage.getItem('name')

  //   localStorage.getItem('dark_mode') === 'true' ? setDarkMode(true) : setDarkMode(false);
  // }, []);


  if (location.pathname === "/ridebooked") {
    return null;
  } else if (page === 0) {
    return (
      <Bookrideform
        setPage={setPage}
        rideObj={rideObj}
        setRideObj={setRideObj}
        darkMode={darkMode}
      />
    );
  } else if (page === 1) {
    return (
      <RideDetails
        setPage={setPage}
        page={page}
        rideObj={rideObj}
        setRideObj={setRideObj}
        darkMode={darkMode}
        hasComeBack={hasComeBack}
        setHasComeBack={setHasComeBack}
      />
    );
  } else if (page === 2) {
    return (
      <OrganizationList
        setPage={setPage}
        rideObj={rideObj}
        setRideObj={setRideObj}
        darkMode={darkMode}
      />
    );
  } else if (page === 3) {
    return (
      // <ScheduledrideDone
      //   setPage={setPage}
      //   rideObj={rideObj}
      //   setRideObj={setRideObj}
      //   darkMode={darkMode}
      // />

      <RideForSureDetails
        setPage={setPage}
        rideObj={rideObj}
        setRideObj={setRideObj}
        darkMode={darkMode}
      />
    );
  } else if (page === 4) {
    return (
      <SchedulerideFail
        setPage={setPage}
        rideObj={rideObj}
        setRideObj={setRideObj}
        darkMode={darkMode}
      />
    );
  } else if (page === 5) {
    return (
      <ScheduledrideDone
        setPage={setPage}
        rideObj={rideObj}
        setRideObj={setRideObj}
        darkMode={darkMode}
      />
    );
  }
};

export default Home;
