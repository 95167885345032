import React from 'react'
import { useEffect } from 'react'
import './paymentDone.css'
import BookingDone from '../../assets/payment-successful.gif'

const PaymentDone = () => {
  
  return (
    <div className='payed'>
        <img src={BookingDone}  alt="Paymentdone" />

        <div className="from-row">
                    <h2>Ride ID:</h2>
                    <h5>7999897</h5>
                </div>
                <div className="from-row">
                    <h2>Ride Booking Status:</h2>
                    <h5>Airport pickup</h5>
                </div>
                <div className="from-row">
                    <h2>Ride Provider:</h2>
                    <h5>Indira Gandhi International Airport</h5>
                </div>

    </div>
  )
}

export default PaymentDone