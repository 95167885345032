//get city list
export const getCity = (callback) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  fetch(`${process.env.REACT_APP_MIDDLEWARE}/api/cities`, requestOptions)
    .then((response) => response.text())
    .then((result) => callback(result))
    .catch((error) => console.log("error", error));
};

//get services list
export const getServices = (callback, codeNum) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    code: codeNum,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(`${process.env.REACT_APP_MIDDLEWARE}/api/ride/services`, requestOptions)
    .then((response) => response.text())
    .then((result) => callback(result))
    .catch((error) => console.log("error", error));
};

//get area
export const getArea = (callback, codeNum, placeName) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    code: codeNum,
    search: placeName,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(`${process.env.REACT_APP_MIDDLEWARE}/api/localities`, requestOptions)
    .then((response) => response.text())
    .then((result) => callback(result))
    .catch((error) => console.log("error", error));
};

export const updateUserData = (callback, authtoken, bookingId, rideObj) => {
  let {
    from,
    to,
    ride_id,
    city,
    locality,
    ride_type,
    date,
    ride_subtype,
    time,
    distance,
    category,
    username,
    rider,
    duration,
    category_name,
    form_id,
    message,
  } = rideObj.data || {};
  var myHeaders = new Headers();
  myHeaders.append("authtoken", authtoken);
  myHeaders.append("Content-Type", "application/json");

  console.log("to", to);
  console.log("from", from);

  var raw = JSON.stringify({
    mynId: username,
    rideId: bookingId,
    data: {
      username: username,
      ride_id: bookingId,
      source: from,
      destination: to,
      city_code: city,
      locality_id: locality,
      trip_type: ride_type,
      sub_trip_type: ride_subtype,
      date: date,
      time: time,
      category: category_name,
      category_code: category,
      distance: distance,
      massage: message,
    },
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(`${process.env.REACT_APP_MIDDLEWARE}/ride/booking/save`, requestOptions)
    .then((response) => response.text())
    .then((result) => callback(result))
    .catch((error) => console.log("error", error));
};

export const getUserdata = (callback, token, booking_id) => {
  console.log(booking_id);
  var myHeaders = new Headers();
  myHeaders.append("authtoken", token);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    rideId: booking_id,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(`${process.env.REACT_APP_MIDDLEWARE}/ride/booking/info`, requestOptions)
    .then((response) => response.text())
    .then((result) => callback(result))
    .catch((error) => console.log("error", error));
};

// check cab Availabilities
export const checkAvailabilities = (callback, newtime, newdate, userData) => {
  const {
    ride_id,
    locality,
    city,
    ride_subtype,
    ride_type,
    category,
    distance,
    to,
    from,
    rider,
  } = userData || {};

  let riderInfo = {
    riderId: userData.username,
    riderName: rider?.name,
    riderContact: rider?.contact,
  };
  let destinations = { name: to?.title, latlong: [to?.lat, to?.long] };
  let sources = { name: from?.title, latlong: [from?.lat, from?.long] };

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  console.log("ride_subtype", ride_subtype);

  var raw = JSON.stringify({
    rideId: ride_id,
    riderInfo: riderInfo,
    code: city,
    locId: locality,
    tripType: ride_type,
    subTripType: ride_subtype,
    date: newdate,
    time: newtime,
    category: "regular",
    categoryCode: category,
    distance: distance,
    source: sources,
    destination: destinations,
  });

  console.log("rawtest", raw);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    `${process.env.REACT_APP_MIDDLEWARE}/api/partners/availabilities`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => callback(result))
    .catch((error) => console.log("error", error));
};

// Create Booking
export const setBooking = (
  callback,
  partnerId,
  partnership,
  newtime,
  newdate,
  userData,
  vechCat
) => {
  const {
    ride_id,
    ride_subtype,
    city,
    ride_type,
    locality,
    locality_name,
    distance,
    to,
    from,
    rider,
    duration,
    order_id,
    payment_id,
    amount,
    currency,
    fixed_price_offer
  } = userData || {};
  let customerInfo = {
    name: rider?.name,
    email: rider?.email,
    mobile: rider?.contact,
    pickupAddress: locality_name,
  };

  const currTimestamp = Math.floor((Date.now() / 1000) * 1000);
  console.log("newtime", newtime);
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    v_id: partnerId,
    v_mode: partnership,
    ride_id: ride_id,
    customerInfo: customerInfo,
    bookingInfo: {
      categoryId: vechCat,
      city: city,
      localityId: locality,
      tripType: ride_type,
      subTripType: ride_subtype,
      date: newdate,
      time: newtime,
      distance: distance,
      pick_latlng: `${from?.lat}, ${from?.long}`,
      pick_name: from.title,
      drop_latlng: `${to?.lat}, ${to?.long}`,
      drop_name: to.title,
      duration: duration,
      vechSegment: "ECONOMY",
      fixed_price_offer
    },
    paymentInfo: {
      order_id: order_id,
      payment_id: payment_id,
      amount: amount,
      currency: currency,
      timestamp: currTimestamp,
    },
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    `${process.env.REACT_APP_MIDDLEWARE}/api/partners/booking`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => callback(result))
    .catch((error) => console.log("error", error));
};

export const getUserDetail = (callback, mynId) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("API-Key", process.env.REACT_APP_API_KEY);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(
    `${process.env.REACT_APP_ID_SERVER}/mynid/rest/userinfo/userexists/${mynId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => callback(result))
    .catch((error) => console.log("error", error));
};

export const getplaceAddress = (callback, searchStr = "") => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  fetch(
    `https://maps.googleapis.com/maps/api/place/textsearch/json?query=123%20${searchStr}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => callback(result))
    .catch((error) => console.log("error", error));
};

export const getLocationName = (callback, lat, long) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&result_type=street_address|sublocality|premise|airport&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => callback(result))
    .catch((error) => console.log("error", error));
};

export const offlineAvail = (callback, rideId) => {
  var myHeaders = new Headers();
  myHeaders.append("api-secret", "63543373b6d381ba3249519e");
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    rideid: rideId,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    `${process.env.REACT_APP_OFFLINE_PARTNER_URL}/ride/responded`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => callback(result))
    .catch((error) => console.log("error", error));
};
