import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./rideForSureDetails.css";
import { setBooking } from "../../api/server";

import backButtonImg from "../../assets/backButtonImg.png";
import backButtonImgDark from "../../assets/backButtonImgDark.png";
import loaderimg from "../../assets/loader-cab.svg";
import savaariImg from "../../assets/savaariImg.png";
import carzOnRentImg from "../../assets/carzOnRentImg.png";
import megaCabsImg from "../../assets/megaCabsImg.png";
import routematicImg from "../../assets/routematicImg.png";
import wavesCabsImg from "../../assets/wavescabImg.png";
import gozoCabsImg from "../../assets/gozoCabsImg.png";

function RideForSureDetails({ setPage, rideObj, setRideObj, darkMode }) {
  const search = useLocation().search;
  const authtoken = new URLSearchParams(search).get("authtoken");

  const [showoffers, setShowoffers] = useState(true);
  const [loader, setLoader] = useState(false);
  const [vendorImg, setVendorImg] = useState();
  const [rideType, setRideType] = useState();

  let userData = rideObj?.data;
  const str = userData.date;
  const [year, month, day] = str.split("-");
  const newdate = [day, month, year].join("-");

  function convertTo24HourFormat(time) {
    var timeArray = time.split(":");
    var hour = parseInt(timeArray[0]);
    var minute = parseInt(timeArray[1]);

    if ((time.indexOf("pm") != -1 || time.indexOf("PM") != -1) && hour !== 12) {
      hour += 12;
    }

    if ((time.indexOf("am") != -1 || time.indexOf("AM") != -1) && hour === 12) {
      hour = 0;
    }

    var hourString = hour.toString().padStart(2, "0");
    var minuteString = minute.toString().padStart(2, "0");

    return hourString + ":" + minuteString;
  }

  var newtime = convertTo24HourFormat(userData.time);
  // console.log("userData.time", userData.time);
  // console.log("newtime", newtime);

  useEffect(() => {
    // setting header vendor img

    if (userData.vendor_name.toLowerCase() === "savaari") {
      setVendorImg(savaariImg);
    } else if (userData.vendor_name.toLowerCase() === "carzonrent") {
      setVendorImg(carzOnRentImg);
    } else if (userData.vendor_name.toLowerCase() === "megacabs") {
      setVendorImg(megaCabsImg);
    } else if (userData.vendor_name.toLowerCase() === "routematic") {
      setVendorImg(routematicImg);
    } else if (userData.vendor_name.toLowerCase() === "wavescab") {
      setVendorImg(wavesCabsImg);
    } else if (userData.vendor_name.toLowerCase() === "gozo") {
      setVendorImg(gozoCabsImg);
    }

    //setting ride_type

    if (userData.ride_type === "local") {
      setRideType(
        `For ${userData.ride_subtype} Hrs/${userData.ride_subtype}0kms`
      );
    } else if (userData.ride_type === "airport") {
      if (userData.ride_subtype === "pick_airport") {
        setRideType("Airport pickup");
      } else {
        setRideType("Airport dropoff");
      }
    }
  }, []);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    setLoader(true);
    let { partnerId, pType, vechCat, vendor_name } = userData;
    let gstRate = 0.05; // 5% GST rate
    console.log("vendor_name", vendor_name);
    let fareamount = vendor_name === "wavescab" ?  Math.round(userData.amount + (userData.amount * gstRate)) : userData.amount;

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    var data = {
      amount: parseInt(fareamount) * 100,
      currency: "INR",
      receipt: userData.rideId,
    };

    // creating a new order
    const result = await axios.post(
      `${process.env.REACT_APP_MIDDLEWARE}/payments/orders`,
      data
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_CLIENT_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: localStorage.getItem("name"),
      description: "Payment for schedule ride",
      image: "",
      order_id: order_id,
      handler: async function (response) {
        if (
          typeof response.razorpay_payment_id !== undefined &&
          response.razorpay_payment_id !== null &&
          response.razorpay_payment_id !== ""
        ) {
          const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };

          rideObj.data.order_id = response.razorpay_order_id;
          rideObj.data.payment_id = response.razorpay_payment_id;
          rideObj.data.amount = fareamount;
          rideObj.data.currency = "INR";
          setRideObj(rideObj);

          const result = await axios.post(
            `${process.env.REACT_APP_MIDDLEWARE}/payments/success`,
            data
          );

          if (result.data.success) {
            const notificationdata = {
              mynid: userData.username,
              amount: rideObj.data.amount,
              authToken: authtoken,
            };

            const notificationResult = await axios.post(
              `${process.env.REACT_APP_MIDDLEWARE}/payments/notification`,
              notificationdata
            );

            const payment_info = await axios.get(
              `${process.env.REACT_APP_MIDDLEWARE}/payments/paymentInfo/` +
                result.data.paymentId
            ); //get details of the payment like payment mode payment status

            if (payment_info?.data?.status === "captured") {
              //insert into master table i.e. booking confirm API
              const data = {
                ride_id: userData.ride_id,
                rider: {
                  id: userData.username,
                  name: userData.rider.name,
                  contact: userData.rider.contact,
                },
                source: userData.from.title,
                destination: userData.to.title,
                pickup_date: userData.date,
                pickup_time: userData.time,
                fare: fareamount,
                order_id: response.razorpay_order_id,
                payment_id: response.razorpay_payment_id,
                payment_status: payment_info?.data?.status,
                provider_id: partnerId,
                provider_name: vendor_name,
              };

              const saveBooking = await axios.post(
                `${process.env.REACT_APP_MIDDLEWARE}/ride/booking/confirm`,
                {
                  rideId: userData.ride_id,
                  data,
                }
              );

              bookNow(partnerId, pType, vechCat);
            }
          }
        }
      },
      modal: {
        ondismiss: function () {
          setLoader(false);
        },
      },
      prefill: {
        name: "",
        email: "",
        contact: "",
      },
      notes: {},
      theme: {
        color: "#009E52",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const setBookingBtn = () => {
    displayRazorpay();
  };

  const bookNow = (partnerId, partnershipType, vechCat) => {
    setBooking(
      async (result) => {
        const res = JSON.parse(result);
        const bookingStatus = res?.result;
        // console.log("bookingStatus", bookingStatus);
        // console.log("booking_confirm.data.status", booking_confirm.data.status);
        let payload;
        if (bookingStatus?.booking_id) {
          rideObj.data.booking_id = bookingStatus?.booking_id;
          rideObj.data.booking_status = bookingStatus?.booking_status;
          rideObj.data.partner_id = bookingStatus?.partner_id;
          rideObj.data.partner_name = bookingStatus?.partner_name;
          rideObj.data.payment_id = bookingStatus?.payment_id;

          setRideObj(rideObj);

          payload = {
            booking_id: bookingStatus?.booking_id,
            booking_status: "CONFIRMED",
          };

          const booking_confirm = await axios.put(
            `${process.env.REACT_APP_MIDDLEWARE}/ride/booking/confirm`,
            {
              rideId: userData.ride_id,
              data: payload,
            }
          );

          let notificationdata = {
            mynid: userData.username,
            provider_name: bookingStatus?.partner_name,
            bookingId: bookingStatus?.booking_id,
            booking_status: bookingStatus?.booking_status,
            authToken: authtoken,
          };

          const bookingNotification = await axios.post(
            `${process.env.REACT_APP_MIDDLEWARE}/ride/booking/confirm/notification`,
            notificationdata
          );

          setLoader(false);
        } else {
          payload = {
            booking_status: "FAILED",
          };
          setLoader(false);
          setPage(4);
        }

        const booking_confirm = await axios.put(
          `${process.env.REACT_APP_MIDDLEWARE}/ride/booking/confirm`,
          {
            rideId: userData.ride_id,

            data: payload,
          }
        ); // console.log("booking_confirm.data.status", booking_confirm.data.status);

        if (bookingStatus?.booking_id) {
          setPage(5);
          setShowoffers(true);
        }
      },
      partnerId,
      partnershipType,
      newtime,
      newdate,
      userData,
      vechCat
    );
  };
  var gstRate = 0.05
  return (
    <div
      className={darkMode ? "listDetails darkModeBackground" : "listDetails"}
    >
      {loader && (
        <div className={`loaderImgDiv ${darkMode && "loaderBackgroundDark"}`}>
          <img alt="loaderimg" src={loaderimg} />

          <span>
            Please wait,
            <br />
            your payment is under process....
          </span>
        </div>
      )}
      <div className="locationDetails">
        <div className="rideDetails">
          <button onClick={() => setPage(2)}>
            <img
              alt="backButtonImg"
              src={darkMode ? backButtonImgDark : backButtonImg}
            />
          </button>
          <div className="rideDetails_left">
            <h3>
              RideForSure <sub id="rideforsure_sub_text">TM</sub> Details
            </h3>
          </div>
        </div>
        <div className="fromDetails">
          <div>
            <div>
              <h3>{userData.vendor_name}</h3>
              {vendorImg && <img alt="savaariImg" src={vendorImg} />}
            </div>
            <h2
              className={`rideforsurePrice ${
                darkMode && "darkRideforsureText"
              }`}
            >
              ₹ {userData.vendor_name === "wavescab" ?  Math.round(userData.amount + (userData.amount * gstRate)) : userData.amount} <span style={{fontSize:"12px"}}> including GST </span>
            </h2>
          </div>
        </div>
        <div className="fromDetails">
          <h3>City:</h3>
          <p>{userData.city_name}</p>
        </div>
        <div className="fromDetails">
          <h3>Ride type:</h3>
          <p>{rideType}</p>
        </div>
        <div className="fromDetails">
          <h3>From:</h3>
          <p>{userData.from.title}</p>
        </div>
        {userData.to.title && (
          <div className="fromDetails">
            <h3>To:</h3>
            <p>{userData.to.title}</p>
          </div>
        )}
        <div className="fromDetails">
          <h3>Date and time</h3>
          <p>{`${newdate} , at ${userData.time}`}</p>
        </div>
        <div className="fromDetails">
          <h3>Car type</h3>
          <p>{userData.category_name}</p>
        </div>
        <div className="fromDetails">
          <h3>Minimum seats</h3>
          {/* <p>{userData.date}</p> */}
          <p>4</p>
        </div>
        {userData.message && (
          <div className="fromDetails">
            <h3>Message to the driver</h3>
            <p>{userData.message}</p>
          </div>
        )}

        {!userData.to.title && (
          <div div className="fromDetails">
            <h3>Inclusions in the Fare</h3>
            <p>Base Fare, Driver Allowances & GST</p>
          </div>
        )}

        {!userData.to.title && (
          <div className="fromDetails">
            <h3>Exclusions on the Fare</h3>
            {/* <p>{userData.date}</p> */}
            <p>• Night Allowance</p>
            <p>• Toll/State Tax and Parking </p>
            <p>
              • Charges will vary if you extend the kilometres, as per the fleet
              policy. Charges will vary if you extend the timings, as per the
              fleet policy.
            </p>
          </div>
        )}

        <div id="bottom_div">
          <p id="mand_message">Pay to confirm your ride*</p>
          <button
            className={`scheduleCabButton ${darkMode && "darkModeBtn"}`}
            onClick={() => setBookingBtn()}
          >
            Schedule a cab
          </button>
        </div>
      </div>
    </div>
  );
}

export default RideForSureDetails;
