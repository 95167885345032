import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { useState } from "react";
// import Choosetime from './pages/choosetime/Choosetime'
// import OperatorOffers from "./pages/OperatorOffers/OperatorOffers";
// import Termcondition from "./pages/termcondition/Termcondition";
// import Myride from "./pages/myride/Myride";
// import Bookrideform from "./pages/bookrideform/Bookrideform";
// import ScheduledrideDetails from "./pages/scheduledrideDetails/ScheduledrideDetails"
import ScheduledrideDone from "./pages/scheduledrideDone/ScheduledrideDone"
import PaymentDone from "./pages/pyamentDone/PaymentDone";
// import RideDetails from "./pages/rideDetails/RideDetails";
import Home from "./pages/Home/Home";

function App() {
  // const [bookingDetail, setBookingDetail] = useState()

  return (
    <BrowserRouter>
      <Home />
      <Routes>
        {/* <Route path="/" element={<Bookrideform />} />
        <Route path="/choosetime" element={<Choosetime />} />
        <Route path="/termscondition" element={<Termcondition />} />
        <Route path="/operatoroffers" setBookingDetail={setBookingDetail} element={<OperatorOffers />} />
        <Route path="/myride" element={<Myride />} />
        <Route path="/scheduledrideDetails" bookingDetail={bookingDetail} element={<ScheduledrideDetails />} />
        <Route path="/ridedetails" element={<RideDetails />} />*/}
        <Route path="/scheduledrideDone" element={<ScheduledrideDone />} />
        <Route path="/ridebooked" element={<PaymentDone />} />

      </Routes>

    </BrowserRouter>
    //<Map />
  );
}

export default App;
