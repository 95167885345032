import "./operatorOffers.css";
import MapImg from "../../assets/map.png";
import Opretordetails from "../../Components/Opretordetails/Opretordetails";

import { checkAvailabilities, getUserdata } from "../../api/server";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { offlineAvail } from "../../api/server";
import Loaderimg from "../../assets/loader-cab.svg";
import { useCallback } from "react";

import backButtonImg from "../../assets/backButtonImg.png";
import backButtonImgDark from "../../assets/backButtonImgDark.png";

const OrganizationList = ({ setPage, rideObj, setRideObj, darkMode }) => {
  const search = useLocation().search;
  const bookingId = new URLSearchParams(search).get("bookingId");
  const authtoken = new URLSearchParams(search).get("authtoken");

  const [availabilitiesData, setAvailabilitiesData] = useState([]);
  const [offlineFleetRes, setOfflineFleetRes] = useState([]);
  const showoffers = true;
 
  let userData = rideObj?.data;

  const str = userData.date;
  const [year, month, day] = str.split("-");
  const newdate = [day, month, year].join("-");

  
  console.log("userData", userData.time);
  function convertTo24HourFormat(time) {
    var timeArray = time.split(":");
    var hour = parseInt(timeArray[0]);
    var minute = parseInt(timeArray[1]);

    if ((time.indexOf("pm") != -1 || time.indexOf("PM") != -1) && hour !== 12) {
      hour += 12;
    }

    if ((time.indexOf("am") != -1 || time.indexOf("AM") != -1) && hour === 12) {
      hour = 0;
    }

    var hourString = hour.toString().padStart(2, "0");
    var minuteString = minute.toString().padStart(2, "0");

    return hourString + ":" + minuteString;
  }

  var newtime = convertTo24HourFormat(userData.time);
  const offlineFleet = useCallback(() => {
    offlineAvail((result) => {
      const res = JSON.parse(result);
      setOfflineFleetRes(res);
    }, userData.ride_id);
  }, [userData.ride_id]);

  const getAvailableCar = useCallback(() => {
    checkAvailabilities(
      (result) => {
        const res = JSON.parse(result);
        if (res) {
          setAvailabilitiesData(res?.result);
          setTimeout(() => {
            offlineFleet();
          }, 20000); //120000
        }
      },
      newtime,
      newdate,
      userData
    );
  }, [newdate, newtime, offlineFleet, userData]);

  useEffect(() => {
    getAvailableCar();
  }, [getAvailableCar]);

  const setBookingBtn = (
    partnerId,
    vechCat,
    OpretorName,
    amount,
    pType,
    carType,
    vendorTnC,
    fixed_price_offer
  ) => {
    // displayRazorpay(partnerId, amount, pType, vechCat);
    rideObj.data.partnerId = partnerId;
    rideObj.data.vechCat = vechCat;
    rideObj.data.amount = amount;
    rideObj.data.pType = pType;
    rideObj.data.vendor_name = OpretorName;
    rideObj.data.category_name = carType;
    rideObj.data.vendorTnC = vendorTnC;
    rideObj.data.fixed_price_offer = fixed_price_offer;
    setPage(3);
  };

  useEffect(() => {
    getUserdata(
      (result) => {
        /* console.log("hello new test", result);
        console.log("ride obj", rideObj); */
        // setRideObj
      },
      authtoken,
      bookingId
    );
  }, [authtoken, bookingId, rideObj]);

  return (
    <>
      {showoffers && (
        <>
          <div className="">
            <div className="map">
              <img src={MapImg} alt="mapImg" />
            </div>
            <div
              className={
                darkMode ? "listDetails darkModeBackground" : "listDetails"
              }
            >
              <button id="back_button_div" onClick={() => setPage(1)}>
                <img
                  alt="backButtonImg"
                  src={darkMode ? backButtonImgDark : backButtonImg}
                />
              </button>

              <div className="availbleCabs">
                {/* <h2>Available cars</h2> */}
                {availabilitiesData?.map((item, i) => {
                  const {
                    vendorId,
                    id,
                    desc,
                    fare,
                    thumbnail,
                    vendorName,
                    vendorTnC,
                    fixed_price_offer
                  } = item || {};
                  if (vendorId) {
                    return (
                      <Opretordetails
                        darkMode={darkMode}
                        OpretorName={vendorName}
                        setBookingBtn={setBookingBtn}
                        Opretorlogo={thumbnail}
                        OpretorPrice={fare}
                        carType={desc}
                        catId={id}
                        vId={vendorId}
                        vendorTnC={vendorTnC}
                        partnership={"online"}
                        key={i}
                        fixed_price_offer={fixed_price_offer}
                      />
                    );
                  }
                  return null;
                })}
                {offlineFleetRes?.length === 0 ? (
                  <div className="loader-parent-div">
                    <div className="loader-div">
                      <img src={Loaderimg} alt="loder" />
                      <span>Please wait,</span>
                      <span>
                        we are still fetching some more options for you....
                      </span>
                    </div>
                  </div>
                ) : (
                  offlineFleetRes?.map((item, i) => {
                    return (
                      <Opretordetails
                        darkMode={darkMode}
                        OpretorName={item?.partner?.name}
                        setBookingBtn={setBookingBtn}
                        Opretorlogo={item?.partner?.logo}
                        OpretorPrice={item?.fare}
                        carType={item?.make + item?.model}
                        vId={item?.partner?.id}
                        partnership={"offline"}
                        key={i}
                      />
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {!showoffers && (
        <>
          <div
            className={
              darkMode
                ? "loader-div loaderDarkModeBackground"
                : "loader-div loaderLightModeBackground"
            }
          >
            <img src={Loaderimg} alt="loder" />
            <span>Please wait, Payment is under process.</span>
          </div>
        </>
      )}
    </>
  );
};

export default OrganizationList;
